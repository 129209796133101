import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import CopyCodeSnippet from './CopyCodeSnippet'

const StyledCard = styled(Card)({
    border: '2px solid #ccc',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    marginTop: '20px',
    marginBottom: '20px',
    backgroundColor: 'unset'
});

const StyledTypography = styled(Typography)({
    color: '#333',
    paddingTop: '15px',
});

const StyledTitle = styled(Typography)({
    color: 'white',
    backgroundColor: '#3f6586',
    paddingLeft: '10px',
    borderRadius: '8px',
});

const WebPageInfo = ({ title, body, code }) => {
    return (
        <StyledCard>
            <CardContent>
                <StyledTitle variant="h5" component="h2" gutterBottom>
                    {title}
                </StyledTitle>
                {/* 
                <StyledTypography variant="body2">
                    Integration of WebPage -
                    <a href="https://tools.stackbaba.com/Single%20Image%20Editor" target="_blank" rel="noopener noreferrer">
                        Single Image Editor
                    </a>
                </StyledTypography> */}

                <StyledTypography variant="body1" gutterBottom>
                    {body}
                </StyledTypography>
            </CardContent>
            <CopyCodeSnippet code={code} ></CopyCodeSnippet>
        </StyledCard>
    );
};

export default WebPageInfo;