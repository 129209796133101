// import {  Grid } from "@mui/material/styles"
import { createContext, useState, useEffect } from "react"
import InitialCustomFilterState from "./components/InitialCustomFilterState"
import ResponsiveAppBar from './components/ResponsiveAppBar';
import SingleImageEditor from './components/Pages/SingleImageEditor';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Slideshow from "./components/Pages/Gallery/Slideshow";
import PassportPhotoGeneration from "./components/Pages/Passport/PassportPhotoGeneration";
import IntegrationDetails from "./components/Pages/Integration/IntegrationDetails";


export const FilterContext = createContext();
function App() {
  const [tabFilter, setTabFilter] = useState('instaFilter');
  const [filterClass, setFilterClass] = useState("");
  const [customFilter, setCustomFilter] = useState(InitialCustomFilterState);
  const [imageFile, setImageFile] = useState(null);
  const value = {
    tabFilter,
    setTabFilter,
    filterClass,
    setFilterClass,
    customFilter,
    setCustomFilter,
    imageFile,
    setImageFile
  }

  // useEffect(() => {
  //   const requestPermission = async () => {
  //     // const permission = await Notification.requestPermission();
  //     Notification.requestPermission().then(function (getperm) {

  //       console.log('Perm granted', getperm)

  //     });
  //     //   if (permission === 'granted') {
  //     //     // Permission was granted
  //     //     console.log('Notification permission granted');
  //     //   } else {
  //     //     // Permission was denied
  //     //     console.log('Notification permission denied');
  //     //   }
  //   };

  //   requestPermission();
  // }, []);

  // useEffect(() => {
  //   const sendNotification = async () => {
  //     const notification = new Notification('My notification title', {
  //       body: 'My notification body',
  //     });

  //     await notification.show();
  //   };

  //   sendNotification();
  // }, []);



  return (
    // <>

    <FilterContext.Provider value={value}>

      <BrowserRouter>
        <ResponsiveAppBar></ResponsiveAppBar>

        <Routes>
          <Route path="/" element={<IntegrationDetails />} />
          {/* <Route index element={<Slideshow />} /> */}
          <Route path="/Single Image Editor" element={<SingleImageEditor />} />
          <Route path="/Passport Photo Generation" element={<PassportPhotoGeneration />} />
          <Route path="/Integration Details" element={<IntegrationDetails />} />
          {/* <Route path="*" element={<NoPage />} /> */}

        </Routes>
      </BrowserRouter>
      {/* <SingleImageEditor /> */}
    </FilterContext.Provider>

  )
}

export default App
