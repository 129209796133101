import React from 'react'
import WebPageInfo from './WebPageInfo';
import { Box } from '@mui/material';
import IframeComponent from './IframeComponent';

export default function IntegrationDetails() {
  const integrationDetails = [
    {
      code: '<iframe src="https://app.stackbaba.com/Single%20Image%20Editor" width="100%" height="1000px" frameBorder="0" style="border: 0;"></iframe>',
      title: "Integration of Single Image Editor",
      body: "Copy the below IFrame tag and Paste into your website",
      iframeSrc: "https://app.stackbaba.com/Single%20Image%20Editor",
      iframeTitle: "Single Image Editor"
    },
    {
      code: '<iframe src="https://app.stackbaba.com/Passport%20Photo%20Generation" width="100%" height="1000px" frameBorder="0" style="border: 0;"></iframe>',
      title: "Integration of Passport Photo Generation",
      body: "Copy the below IFrame tag and Paste into your website",
      iframeSrc: "https://app.stackbaba.com/Passport%20Photo%20Generation",
      iframeTitle: "Passport Photo Generation"
    }
  ];

  return (
    <Box sx={{ marginBottom: '200px' }}>
      {
        integrationDetails.map((integrationObj) =>
          <Box key={integrationObj.title}>
            <WebPageInfo title={integrationObj.title} body={integrationObj.body} code={integrationObj.code}>
            </WebPageInfo>
            <IframeComponent src={integrationObj.iframeSrc} title={integrationObj.iframeTitle}></IframeComponent>
          </Box>
        )
      }

    </Box>
  )
}
