import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import CloseIcon from '@mui/icons-material/Close';

const CopyCodeSnippet = ({ code }) => {
    const [copySuccess, setCopySuccess] = useState(false);

    const handleCopyClick = () => {
        navigator.clipboard.writeText(code);
        setCopySuccess(true);
    };

    const handleCloseSnackbar = () => {
        setCopySuccess(false);
    };

    return (
        <Paper
            sx={{
                p: 2,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                backgroundColor: '#a2a5a6',
                marginLeft: '15px',
                marginRight: '15px',
                marginBottom: '50px'
            }}
        >
            <code style={{ overflow: 'hidden' }}>{code}</code>
            <Button
                onClick={handleCopyClick}
                variant="contained"
                endIcon={<FileCopyIcon />}
            >
                Copy
            </Button>
            <Snackbar
                open={copySuccess}
                autoHideDuration={2000}
                onClose={handleCloseSnackbar}
                message="Code copied to clipboard"
                action={
                    <IconButton
                        size="small"
                        aria-label="close"
                        color="inherit"
                        onClick={handleCloseSnackbar}
                    >
                        <CloseIcon fontSize="small" />
                    </IconButton>
                }
            />
        </Paper>
    );
};

export default CopyCodeSnippet;
