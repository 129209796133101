import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
// import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
// import AdbIcon from '@mui/icons-material/Adb';

import { Outlet, Link } from "react-router-dom";
import ChatBot from 'react-simple-chatbot';
import Partners from './Pages/Partners/Partners';

const StudioTitle = "Online Studio";
const pages = ['Single Image Editor', 'Passport Photo Generation', 'Integration Details'];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  //ChatBot
  const BotRedirect = ({ url, message }) => {
    return (
      <div>
        <a href={url} target="_blank">
          {message}
        </a>
      </div>
    );
  };

  //ChatBot
  const steps = [
    {
      id: '1',
      message: 'Hi, I am ChatBot',
      trigger: '2',
    },
    {
      id: '2',
      message: 'What is your name?',
      trigger: '3',
    },
    {
      id: '3',
      user: true,
      trigger: '4.1',
    },
    {
      id: '4.1',
      message: 'Hi {previousValue}!',
      trigger: '4',
    },
    {
      id: '4',
      message: 'Please select one of the service you want to visit?',
      trigger: '5',
    },
    {
      id: "5",
      options: [
        { value: 1, label: "Single Image Editor", trigger: "6" },
        { value: 2, label: "Bulk Image Editor", trigger: "7" },
        { value: 3, label: "B2B Pricing", trigger: "8" },
      ]
    },
    {
      id: "6",
      component: (
        <BotRedirect
          message="Single Image Editor URL"
          url="/Single%20Image%20Editor"
        />
      ),
      trigger: "4"
    },
    {
      id: "7",
      component: (
        <BotRedirect
          message="Bulk Image Editor URL"
          url="/Bulk%20Image%20Editor"
        />
      ),
      trigger: "4"
    },
    {
      id: "8",
      component: (
        <BotRedirect
          message="B2B Pricing"
          url="/B2B Pricing"
        />
      ),
      trigger: "4"
    }
  ];


  return (
    <>
      <React.StrictMode>
        <AppBar position="relative">
          <Container maxWidth="xl">
            <Toolbar disableGutters>
              {/* <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} /> */}
              <Typography
                variant="h6"
                noWrap
                component="a"
                href="/"
                sx={{
                  mr: 2,
                  display: { xs: 'none', md: 'flex' },
                  fontFamily: 'monospace',
                  fontWeight: 700,
                  letterSpacing: '.3rem',
                  color: 'inherit',
                  textDecoration: 'none',
                }}
              >
                {StudioTitle}
              </Typography>

              <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="inherit"
                >
                  {/* <MenuIcon /> */}
                  <span className="material-symbols-outlined">
                    menu
                  </span>
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { xs: 'block', md: 'none' },
                  }}
                >
                  {/* {pages.map((page) => (
                  <MenuItem key={page} onClick={handleCloseNavMenu}  >
                    <Typography textAlign="center">{page}</Typography>
                  </MenuItem>
                ))} */}
                  {pages.map((page) => (
                    <MenuItem key={page}
                      onClick={handleCloseNavMenu}
                      component={Link} to={"/" + page}
                    >
                      <Typography textAlign="center">{page}</Typography>
                      {/* <Button
                  key={page}
                  // onClick={handleCloseNavMenu}
                  component={Link} to={"/" + page}
                  sx={{ my: 2, color: 'white', display: 'block', margin: '1rem' }}
                >
                  {page}
                </Button> */}
                    </MenuItem>
                    // <Link key={page} to={"/" + page}>{page}</Link>
                  ))}
                </Menu>
              </Box>
              {/* <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} /> */}
              <Typography
                variant="h5"
                noWrap
                component="a"
                href="/"
                sx={{
                  mr: 2,
                  display: { xs: 'flex', md: 'none' },
                  flexGrow: 1,
                  fontFamily: 'monospace',
                  fontWeight: 700,
                  letterSpacing: '.3rem',
                  color: 'inherit',
                  textDecoration: 'none',
                }}
              >
                {StudioTitle}
              </Typography>
              <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                {pages.map((page) => (
                  <Button
                    key={page}
                    // onClick={handleCloseNavMenu}
                    component={Link} to={"/" + page}
                    sx={{ my: 2, color: 'white', display: 'block', margin: '1rem' }}
                  >
                    {page}
                  </Button>
                  // <Link key={page} to={"/" + page}>{page}</Link>
                ))}
              </Box>

              {/* <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box> */}
            </Toolbar>
          </Container>
        </AppBar>
        <Outlet />
      </React.StrictMode>
      <Box>
        {/* <Partners></Partners> */}
        <ChatBot steps={steps} floating={true} />
      </Box>
    </>
  );
}
export default ResponsiveAppBar;