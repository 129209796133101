import React from 'react';
import Paper from '@mui/material/Paper';

const IframeComponent = ({ src, title }) => {
  return (
    <Paper
      elevation={3}
      sx={{
        width: '100%',
        height: '1000px',
        overflow: 'hidden',
        padding: '10px',
        borderRadius: '8px',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
        marginBottom: 2,
      }}
    >
      <iframe
        src={src}
        title={title}
        style={{
          border: '0',
          width: '100%',
          height: '100%',
        }}
      ></iframe>
    </Paper>
  );
};

export default IframeComponent;